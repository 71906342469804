import { CreateImageDto } from '../fixsirat-admin-backend/src/images/dto/create-image.dto';

export enum Severity {
  Low = 1,
  Medium = 2,
  High = 3,
}
export enum MaterialType {
  A01 = 'A01',
  A02 = 'A02',
  A03 = 'A03',
  B01 = 'B01',
  B02 = 'B02',
  B03 = 'B03',
}

export enum IssueStatus {
  PENDING = 'Pending',
  RESOLVED = 'Resolved',
  CLOSED = 'Closed',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  RETURNED = 'Returned',
  REOPENED = 'Reopened',
  REMOVED = 'Removed',
}
export interface imageType {
  dateTaken?: string;
  imageLink: string;
  issueId?: string;
  isBefore?: boolean;
  isSelectedImage?: boolean;
}

export interface issueImages {
  issueImages?: imageType[];
}
export interface issue extends issueImages {
  reporter: string;
  userName: string;
  address?: string;
  severity: Severity;
  mapURL: string;
  images?: CreateImageDto[];
  dateTaken?: string;
  issueDescription?: string;
  issueType: string;
  fixTime?: number;
  issueStatus?: IssueStatus;
  location?: {
    coordinates: [number, number];
    type?: string;
  };
  assignee?: string;
  materialType: MaterialType;
  quantityUsed: Number;
  depth: Number;
  diameter: Number;
}
export interface commentType {
  comment?: string;
  reporter: string;
  userName: string;
  issueId: string;
  audioLink?: string;
  createdAt?: string;
}
export interface notification {
  userName: string;
  userEmail: string;
  publicKey: string;
  privateKey: string;
  endpoint: string;
  expirationTime: string;
  p256dh: string;
  auth: string;
}
export enum  NotificationsPermissionStatus{
  GRANTED='granted',
  DENIED='denied',
}
export enum UserPermissionType {
  CREATE_ISSUE = "create:issue",
  READ_ISSUE = "read:issue",
  UPDATE_ISSUE = "update:issue",
  DELETE_ISSUE = "delete:issue",
  UPDATE_STATUS_ISSUE = "updateStatus:issue",
  UPDATE_ASSIGNEE_ISSUE = "updateAssignee:issue",
  MANAGE_USERS="manageUsers:admin",
}
export enum UserRole {
  SUPER_ADMIN = "SuperAdmin",
  ADMIN = "Admin",
  OPERATION = "Operation",
  USER = "User",
  GUEST = "Guest",
}
export interface AuthUserAccessProps {
  roleTypes: [];
  permissions: [];
}
export interface AuthUser{
      user_id: string;
      email: string;
      picture: string;
      name: string;
      blocked?:boolean;
}
export interface AuthRole{
  id:string;
  name:string;
  description:string;
  selected?:boolean;
}
export interface AuthSelectedRole{
  id:string;
  name:string;
  description:string;
  selected:boolean;
}
export interface AuthPermission{
  value:string;
  description:string;
}

export interface UserInvite{
  email:string;
  date:string;
  inviteAccepted:boolean;
}

export enum fieldType {
  STRING = 'string',
  NUMBER = 'number',
}